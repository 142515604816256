import { selectIn } from 'common/util/selector'

const base = (state, props) => state.main.devices.custom_attributes

export const customAttributesList = selectIn(base, 'list')
export const customAttributesListLoading = selectIn(base, 'list_loading')
export const attributesFilterValues = selectIn(base, 'attributes_filter_values')
export const customAttributeDetails = selectIn(base, 'details')
export const customAttributeDetailsLoading = selectIn(base, 'details_loading')
export const customAttributeDetailsError = selectIn(base, 'details_error')
export const customAttributeDevices = selectIn(base, 'devices')
export const customAttributeDevicesLoading = selectIn(base, 'devices_loading')
export const customAttributeDevicesSortKey = selectIn(
  base,
  'attribute_devices_sort_key'
)
export const customAttributeDevicesSortDirection = selectIn(
  base,
  'attribute_devices_sort_direction'
)
export const customAttributeDevicesFilterOptions = selectIn(
  base,
  'devices_filter_options'
)
export const customAttributeDevicesFilterOptionsLoading = selectIn(
  base,
  'devices_filter_options_loading'
)
export const customAttributeDevicesFilterValues = selectIn(
  base,
  'devices_filter_values'
)
export const customAttributeDevicesTotal = selectIn(base, 'total_devices')
