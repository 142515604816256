import { fromJS } from 'immutable'
import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import {
  CHANGE_ATTRIBUTE_FILTER,
  CLEAR_DEVICES,
  SET_DEVICES_SORT,
  SET_DEVICES_FILTERS
} from './actions'
import { SORT_ASC } from 'common/constants'

export const initialAttributesFilterValues = fromJS({
  query: ''
})

const initialState = fromJS({
  ...apiInitialState('list'),
  ...apiInitialState('details'),
  ...apiInitialState('devices'),
  ...apiInitialState('filter_options'),
  ...apiInitialState('total_devices'),
  attribute_devices_sort_key: 'value',
  attribute_devices_sort_direction: SORT_ASC,
  attributes_filter_values: initialAttributesFilterValues,
  attribute_devices_filter_values: fromJS({})
})

function devicesAttributesReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(
    state,
    action,
    'custom_attribute',
    initialState
  )
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case CHANGE_ATTRIBUTE_FILTER:
      const singularFilterKey = action.data['filter']
      const singularFilterValue = action.data['value']
      return state.mergeIn(
        ['attributes_filter_values'],
        fromJS({
          [singularFilterKey]: singularFilterValue
        })
      )
    case CLEAR_DEVICES:
      return state.set('devices', fromJS([]))
    case SET_DEVICES_SORT:
      return state.merge(
        fromJS({
          attribute_devices_sort_key: action.sortKey,
          attribute_devices_sort_direction: action.sortDirection
        })
      )
    case SET_DEVICES_FILTERS:
      return state.mergeIn(
        ['attribute_devices_filter_values'],
        fromJS(action.filters)
      )
    default:
      return state
  }
}

export default devicesAttributesReducer
